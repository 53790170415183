/* NOTE: these CSS is for both agent and client portal */

@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');

body {
  margin: 0;
  font-family: 'DM Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 14px;
}

html,
#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-popover-inner-content {
  width: 210px;
}

.shift-popover-overlay .ant-popover-inner {
  border-radius: 10px;
}

.shift-popover-overlay .ant-popover-inner-content {
  width: 274px;
  padding: 16px;
}

.shift-popover-overlay.ant-popover-placement-top {
  padding-bottom: 0;
}

.shift-popover-overlay.ant-popover-placement-bottom {
  padding-top: 0;
}

.chart-tooltip {
  position: absolute;
  top: 0;
  background: rgb(255, 255, 255);
  border: 1px solid #ffffff;
  opacity: 1;
  transition: opacity 0.25s, left 0.25s, top 0.25s;
  pointer-events: none;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  border-radius: 8px;
  /* Shadow/lg */
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.05),
    0px 12px 16px -4px rgba(16, 24, 40, 0.1);
}

.chart-tooltip--hidden {
  opacity: 0;
}

.chart-tooltip__label {
  width: 100%;
  text-align: left;
  padding: 0 0 16px 0;
  white-space: nowrap;
  font-size: 18px;
  line-height: 1.1111;
  font-weight: 700;
  color: #101828;
}

.chart-tooltip__set {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.chart-tooltip__set:not(:last-child) {
  margin-bottom: 13px;
}

.chart-tooltip__set-point {
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #444ce7;
  margin-right: 10px;
}

.chart-tooltip__set-point.chart-tooltip__set-point--your {
  background-color: #444ce7;
}

.chart-tooltip__set-point.chart-tooltip__set-point--avg {
  background-color: #ee46bc;
}

.chart-tooltip__set-point.chart-tooltip__set-point--top {
  background-color: #0086c9;
}

.chart-tooltip__set-point.chart-tooltip__set-point--tier1 {
  background-color: #475467;
}

.chart-tooltip__set-point.chart-tooltip__set-point--tier2 {
  background-color: #0086c9;
}

.chart-tooltip__set-point.chart-tooltip__set-point--tier3 {
  background-color: #ee46bc;
}

.chart-tooltip__set-label {
  font-size: 14px;
  font-weight: 700;
  color: #101828;
  flex: 1;
  margin-right: 10px;
}

.chart-tooltip__set-value {
  font-size: 14px;
  font-weight: 700;
  color: #203d50;
}

.ant-tooltip--hide-arrow .ant-tooltip-arrow {
  display: none;
}

.ant-tooltip--hide-arrow .ant-tooltip-inner {
  padding: 8px 16px;
}
